const TOKEN_KEY = 'jwtToken'; // Key for localStorage

const authService = {
    login: (token) => {
        localStorage.setItem(TOKEN_KEY, token);
    },

    logout: () => {
        localStorage.removeItem(TOKEN_KEY);
    },

    isLoggedIn: () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (!token) {
            return false;
        }

        try {
            // Check if the token is valid
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
            return Date.now() < expirationTime;
        } catch (error) {
            return false;
        }
    },

    getToken: () => {
        return localStorage.getItem(TOKEN_KEY);
    },
};

export default authService;