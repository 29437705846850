import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {ButtonsPlain} from "../../components/ButtonsPlain";
import {ButtonsPlainWrapper} from "../../components/ButtonsPlainWrapper";
import {CountersIconWeb} from "../../components/CountersIconWeb";
import "./style.css";
import config from "../../config";
import authService from "../../authService";
import {FormsInputActive} from "../../components/FormsInputActive";

export const AccountSettings = () => {
    const [activateAccount, setActivateAccount] = useState(false);
    const [addAccount, setAddAccount] = useState(false);
    const [selectedMarketplace, setSelectedMarketplace] = useState("");
    const [sellers, setSellers] = useState([]);
    const [seller, setSeller] = useState(null);
    const [customer, setCustomer] = useState(null);
    const navigate = useNavigate();
    const [accountName, setAccountName] = useState("");
    const [addAccountError, setAddAccountError] = useState(null);
    const [apiKey, setApiKey] = useState("");

    const generateApiKey = async () => {
        let token = authService.getToken();
        const response = await fetch(`${config.SERVER_URL}/web/customer/apikey/reset`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        if (response.ok) {
            setApiKey(data.apiKey);
        } else {
            alert(data.error);
        }
    }

    useEffect(() => {
        if (!authService.isLoggedIn()) {
            return navigate('/sign-in');
        }
        let token = authService.getToken();
        fetch(`${config.SERVER_URL}/web/customer`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setCustomer(data.customer);
                setSellers(data.sellers.sort((a, b) => a.id - b.id))
            });
    }, []);

    const authAmz = () => {
        if (!seller) {
            alert("Please select a seller account first");
        }
        const url = new URL(`${config.SERVER_URL}/amzauth/registerseller`);
        url.searchParams.append('marketplaceId', selectedMarketplace);
        url.searchParams.append('sellerId', seller.id);
        window.open(url.toString(), '_blank', 'width=500,height=800');

        const startTime = Date.now();
        const intervalId = setInterval(async () => {
            if (Date.now() - startTime > 4 * 60 * 1000) {
                clearInterval(intervalId);
                return;
            }

            // Make a request to the server
            let token = authService.getToken();
            const response = await fetch(`${config.SERVER_URL}/web/customer/sellers?id=${seller.id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();

            if (data.seller.americaConnected) {
                clearInterval(intervalId);
                setSeller(data.seller);
                setActivateAccount(false);
            }
        }, 2000);
    };

    const handleAddAccount = async (e) => {
        console.log("handleAddAccount");
        e.preventDefault();
        let token = authService.getToken();
        const response = await fetch(`${config.SERVER_URL}/web/customer/sellers/add`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({displayName: accountName})
        });
        const data = await response.json();

        if (response.ok) {
            setSellers([...sellers, data.seller]);
            setAddAccount(false);
        } else {
            setAddAccountError(data.message);
        }
    }


    return (
        <div className="account-settings">
            <div className="div-2">

                {addAccount && (<>
                        <div
                            style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: 1000
                            }}
                            onClick={() => {
                                setAddAccount(false);
                                setAccountName("");
                                setAddAccountError(null);
                            }
                            }
                        />
                        <div className="popup-3 add-account">
                            <form onSubmit={handleAddAccount}>
                                {addAccountError && <div className="error-message">{addAccountError}</div>}
                                <FormsInputActive
                                    bgClassName="forms-input-active-7"
                                    className="forms-input-active-6"
                                    text={accountName}
                                    title="Account Name"
                                    type="text"
                                    onChange={(e) => setAccountName(e.target.value)}
                                />
                                <ButtonsPlain className="overlap-group-10" divClassName="buttons-plain-12"
                                              text="Save" type="submit"/>
                            </form>
                        </div>
                    </>
                )}

                {activateAccount && (
                    <>
                        <div
                            style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                zIndex: 1000
                            }}
                            onClick={() => {
                                setActivateAccount(false);
                                setSelectedMarketplace("");
                            }}
                        />
                        <div className="popup-2">
                            <div className="comment">
                                <div className="overlap-group-9">
                                    <p className="add-your-comment">Make sure you are log in to seller central with your
                                        main user</p>
                                    <ButtonsPlain className="buttons-plain-10" divClassName="buttons-plain-11"
                                                  text="Authenticate" onClick={authAmz}/>
                                </div>
                            </div>
                            <div className="title-7">
                                <p className="type-new-project-s-n">Select a Marketplace</p>
                            </div>
                            <div className="flags">
                                {activateAccount.na && (
                                    <>
                                        <input type="image"
                                               className={`flags-auth-9 ${selectedMarketplace === "us" ? "selected" : selectedMarketplace === "" ? "selected" : ""}`}
                                               alt="US" src="/img/flags-02.png"
                                               onClick={() => setSelectedMarketplace("us")}/>
                                        <input type="image"
                                               className={`flags-auth-10 ${selectedMarketplace === "ca" ? "selected" : ""}`}
                                               alt="CA" src="/img/flags-03.png"
                                               onClick={() => setSelectedMarketplace("ca")}/>
                                        <input type="image"
                                               className={`flags-auth-11 ${selectedMarketplace === "mx" ? "selected" : ""}`}
                                               alt="MX" src="/img/flags-04.png"
                                               onClick={() => setSelectedMarketplace("mx")}/>
                                        <input type="image"
                                               className={`flags-auth-12 ${selectedMarketplace === "br" ? "selected" : ""}`}
                                               alt="BR" src="/img/flags-05.png"
                                               onClick={() => setSelectedMarketplace("br")}/>
                                    </>
                                )}

                                {activateAccount.europe && (
                                    <>
                                        <input type="image" className="flags-auth-21" alt="Flags"
                                               src="/img/flags-06.png"/>
                                        <input type="image" className="flags-auth-22" alt="Flags"
                                               src="/img/flags-07.png"/>
                                        <input type="image" className="flags-auth-23" alt="Flags"
                                               src="/img/flags-08.png"/>
                                        <input type="image" className="flags-auth-24" alt="Flags"
                                               src="/img/flags-09.png"/>
                                        <input type="image" className="flags-auth-25" alt="Flags"
                                               src="/img/flags-11.png"/>
                                        <input type="image" className="flags-auth-26" alt="Flags"
                                               src="/img/flags-13.png"/>
                                        <input type="image" className="flags-auth-27" alt="Flags"
                                               src="/img/flags-14.png"/>
                                        <input type="image" className="flags-auth-28" alt="Flags"
                                               src="/img/flags-15.png"/>
                                        <input type="image" className="flags-auth-13" alt="Flags"
                                               src="/img/flags-12.png"/>
                                    </>
                                )}

                                {activateAccount.asia && (
                                    <>
                                        <input type="image" className="flags-auth-14" alt="Flags"
                                               src="/img/flags-19.png"/>
                                        <input type="image" className="flags-auth-15" alt="Flags"
                                               src="/img/flags-20.png"/>
                                        <input type="image" className="flags-auth-16" alt="Flags"
                                               src="/img/flags-21.png"/>
                                        <input type="image" className="flags-auth-17" alt="Flags"
                                               src="/img/flags-22.png"/>
                                        <input type="image" className="flags-auth-20" alt="Flags"
                                               src="/img/flags-18.png"/>
                                        <input type="image" className="flags-auth-18" alt="Flags"
                                               src="/img/flags-16.png"/>
                                        <input type="image" className="flags-auth-19" alt="Flags"
                                               src="/img/flags-17.png"/>
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                )}

                {customer && (
                    <>
                        <div className="sidebar">
                            <div className="overlap-3">
                                <img className="separator" alt="Separator" src="/img/separator-2.svg"/>
                                <div className="title-3">
                                    <div className="text-wrapper-2">Welcome,</div>
                                    <div className="whiteui-store">{customer.displayName}</div>
                                </div>
                                <div className="tasks">
                                    <div className="overlap-4">
                                        <div className="overall-tasks-perfor">Manage your account</div>
                                        <div className="tasks-overview">Accounts overview</div>
                                    </div>

                                    <div className="overlap-7">
                                        {sellers.map(seller => (
                                            <div key={seller.id}>
                                                <div className="counters-icon-web-2">
                                                    <div className="overlap-group-3">
                                                        <div className="number-wrapper">
                                                            <div className="number-2"
                                                                 onClick={() => setSeller(seller)}>{seller.displayName}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <ButtonsPlain
                                        className="buttons-plain-primary-active add-account-button"
                                        divClassName="buttons-plain-instance"
                                        onClick={() => setAddAccount(true)}
                                        text="Add account"
                                    />
                                </div>
                                <p className="tasks-overview-3">Generate Your Api key for our google add-on</p>
                                <ButtonsPlainWrapper
                                    className="buttons-plain-primary-resting"
                                    divClassName="buttons-plain-primary-resting-instance"
                                    text="Install the add-on"
                                    onClick={() => window.open("https://workspace.google.com/marketplace/app/seller_glory/519365229855")}
                                />
                                <ButtonsPlain
                                    className="buttons-plain-primary-active-instance"
                                    divClassName="buttons-plain-2"
                                    text="Generate key"
                                    onClick={generateApiKey}
                                />
                                <div className="api-key-content">
                                    <span>{apiKey}</span>
                                </div>
                            </div>
                        </div>
                    </>)}
                {seller && (<>
                    <div className="bg-2">
                        <div className="UI-forms-basic-input">
                            <div className="placeholder">{seller.displayName}</div>
                            <div className="bg-3"/>
                        </div>
                        <div className="marketplaces">
                            <div className="placeholder-2 ">Connected marketplaces</div>
                            <div className="north-america">
                                North America
                                <ButtonsPlain
                                    type="submit"
                                    className={seller.americaConnected ? "buttons-plain-primary-active connected" : "buttons-plain-primary-active not-connected"}
                                    divClassName="buttons-plain-instance"
                                    text={seller.americaConnected ? "Connected" : "Connect"}
                                    onClick={() => {
                                        if (!seller.americaConnected) {
                                            setActivateAccount({show: true, na: true})
                                        }
                                    }
                                    }
                                />
                                <div className="na-flags">
                                    <img className="flags-9" alt="Flags" src="/img/flags-02.png"/>
                                    <img className="flags-10" alt="Flags" src="/img/flags-03.png"/>
                                    <img className="flags-11" alt="Flags" src="/img/flags-04.png"/>
                                    <img className="flags-12" alt="Flags" src="/img/flags-05.png"/>
                                </div>
                            </div>
                            <div className="asia">
                                Asia
                                <ButtonsPlain
                                    type="submit"
                                    className={seller.asiaConnected ? "buttons-plain-primary-active connected" : "buttons-plain-primary-active not-connected"}
                                    divClassName="buttons-plain-instance"
                                    text={seller.asiaConnected ? "Connected" : "Connect"}
                                    onClick={() => {
                                        if (!seller.asiaConnected) {
                                            setActivateAccount({show: true, asia: true})
                                        }
                                    }
                                    }
                                />
                                <div className="asia-flags">
                                    <img className="flags-14" alt="Flags" src="/img/flags-19.png"/>
                                    <img className="flags-15" alt="Flags" src="/img/flags-20.png"/>
                                    <img className="flags-16" alt="Flags" src="/img/flags-21.png"/>
                                    <img className="flags-17" alt="Flags" src="/img/flags-22.png"/>
                                    <img className="flags-20" alt="Flags" src="/img/flags-18.png"/>
                                    <img className="flags-18" alt="Flags" src="/img/flags-16.png"/>
                                    <img className="flags-19" alt="Flags" src="/img/flags-17.png"/>
                                </div>
                            </div>
                            <div className="europe">
                                <span className="europe-title"> Europe </span>
                                <ButtonsPlain
                                    type="submit"
                                    className={seller.europeConnected ? "buttons-plain-primary-active connected" : "buttons-plain-primary-active not-connected"}
                                    divClassName="buttons-plain-instance"
                                    text={seller.europeConnected ? "Connected" : "Connect"}
                                    onClick={() => {
                                        if (!seller.europeConnected) {
                                            setActivateAccount({show: true, europe: true})
                                        }
                                    }
                                    }
                                />
                                <div className="europe-flags">
                                    <img className="flags-21" alt="Flags" src="/img/flags-06.png"/>
                                    <img className="flags-22" alt="Flags" src="/img/flags-07.png"/>
                                    <img className="flags-23" alt="Flags" src="/img/flags-08.png"/>
                                    <img className="flags-24" alt="Flags" src="/img/flags-09.png"/>
                                    <img className="flags-25" alt="Flags" src="/img/flags-11.png"/>
                                    <img className="flags-26" alt="Flags" src="/img/flags-13.png"/>
                                    <img className="flags-27" alt="Flags" src="/img/flags-14.png"/>
                                    <img className="flags-28" alt="Flags" src="/img/flags-15.png"/>
                                    <img className="flags-13" alt="Flags" src="/img/flags-12.png"/>
                                </div>
                            </div>

                        </div>
                    </div>
                </>)}
            </div>

        </div>
    )
        ;
};
