import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const ButtonsPlainWrapper = ({className, divClassName, text = "Lable", onClick}) => {
    return (
        <div className={`buttons-plain-wrapper ${className}`}>
            <div className={`text-wrapper ${divClassName}`} onClick={onClick}>{text}</div>
        </div>
    );
};

ButtonsPlainWrapper.propTypes = {
    text: PropTypes.string,
};
