import React, {useState} from "react";
import {ButtonsPlain} from "../../components/ButtonsPlain";
import {FormsInputActive} from "../../components/FormsInputActive";
import "./style.css";
import config from "../../config";

export const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState("");
    const [passwordValidation, setPasswordValidation] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const token = new URLSearchParams(window.location.search).get('token');


    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setSuccess(null);

        try {
            if (newPassword !== passwordValidation) {
                setError("Passwords do not match.")
                return;
            }
            const response = await fetch(`${config.SERVER_URL}/auth/resetpassword`, {
                method: 'POST', headers: {
                    'Content-Type': 'application/json'
                }, body: JSON.stringify({resetToken: token, newPassword: newPassword}),
            });

            if (response.ok) {
                const errorData = await response.json();
                if (errorData.error) {
                    setError(errorData.error);
                    return;
                }
                setSuccess("Password reset successfully. Please login with your new password.");
            } else {
                const errorData = await response.json();

                if (errorData.message) {
                    setError(errorData.message);
                } else {
                    setError("Failed. Please try again.");
                }
            }
        } catch (error) {
            setError("An error occurred. Please try again." + error.message);
        }
    };

    return (<div className="sign-in">
        <div className="overlap-wrapper-3">
            <div className="overlap-4">
                <div className="gradient-bg-4"/>
                <div className="image-3">
                    <div className="overlap-5">
                        <img className="path-160" alt="Path" src="/img/path-174.svg"/>
                        <img className="path-copy-3" alt="Path copy" src="/img/path-copy-3.svg"/>
                        <img className="path-161" alt="Path" src="/img/path-175.svg"/>
                        <div className="group-7">
                            <div className="overlap-group-5">
                                <div className="rectangle-58"/>
                                <div className="rectangle-59"/>
                                <div className="rectangle-60"/>
                                <div className="rectangle-61"/>
                                <div className="rectangle-62"/>
                                <div className="rectangle-63"/>
                                <img className="oval-28" alt="Oval" src="/img/oval-29.svg"/>
                            </div>
                        </div>
                        <img className="path-162" alt="Path" src="/img/path-176.svg"/>
                        <img className="group-8" alt="Group" src="/img/group-7.png"/>
                        <div className="rectangle-64"/>
                        <div className="rectangle-65"/>
                        <div className="rectangle-66"/>
                        <div className="rectangle-67"/>
                        <div className="rectangle-68"/>
                        <div className="rectangle-69"/>
                        <div className="rectangle-70"/>
                        <div className="rectangle-71"/>
                        <div className="rectangle-72"/>
                        <div className="rectangle-73"/>
                        <div className="rectangle-74"/>
                        <div className="rectangle-75"/>
                        <div className="rectangle-76"/>
                        <div className="rectangle-77"/>
                        <div className="rectangle-78"/>
                        <div className="rectangle-79"/>
                        <div className="rectangle-80"/>
                        <div className="rectangle-81"/>
                        <div className="rectangle-82"/>
                        <div className="rectangle-83"/>
                        <div className="rectangle-84"/>
                        <div className="rectangle-85"/>
                        <div className="rectangle-86"/>
                        <div className="rectangle-87"/>
                        <div className="rectangle-88"/>
                        <div className="rectangle-89"/>
                        <div className="rectangle-90"/>
                        <div className="rectangle-91"/>
                        <div className="rectangle-92"/>
                        <div className="rectangle-93"/>
                        <div className="rectangle-94"/>
                        <div className="group-9">
                            <div className="rectangle-95"/>
                            <div className="rectangle-96"/>
                            <div className="rectangle-97"/>
                        </div>
                        <img className="shape-30" alt="Shape" src="/img/shape-29.svg"/>
                        <img className="path-163" alt="Path" src="/img/path-177.svg"/>
                        <img className="path-164" alt="Path" src="/img/path-178.svg"/>
                        <img className="path-165" alt="Path" src="/img/path-179.svg"/>
                        <img className="path-166" alt="Path" src="/img/path-180.svg"/>
                        <img className="path-167" alt="Path" src="/img/path-181.svg"/>
                        <img className="oval-29" alt="Oval" src="/img/oval-27.svg"/>
                        <img className="path-168" alt="Path" src="/img/path-182.svg"/>
                        <img className="path-169" alt="Path" src="/img/path-183.svg"/>
                        <img className="path-170" alt="Path" src="/img/path-184.svg"/>
                        <div className="rectangle-94"/>
                        <img className="path-171" alt="Path" src="/img/path-185.svg"/>
                        <img className="path-172" alt="Path" src="/img/path-186.svg"/>
                        <img className="oval-30" alt="Oval" src="/img/oval-28.svg"/>
                        <img className="path-173" alt="Path" src="/img/path-187.svg"/>
                        <img className="path-174" alt="Path" src="/img/path-188.svg"/>
                        <img className="group-10" alt="Group" src="/img/group-6.png"/>
                        <img className="path-175" alt="Path" src="/img/path-189.svg"/>
                    </div>
                </div>
                <div className="bg-5"/>
                <div className="form-4">
                    <form onSubmit={handleSubmit}>
                        {error && <div className="error-message">{error}</div>}
                        {success && <div className="success-message">{success}</div>}
                        <FormsInputActive
                            bgClassName="forms-input-active-7"
                            className="forms-input-active-6"
                            text={newPassword}
                            title="New Password"
                            type="password"
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <FormsInputActive
                            bgClassName="forms-input-inactive-12"
                            className="forms-input-inactive-11"
                            text={passwordValidation}
                            title="Type Again"
                            type="password"
                            onChange={(e) => setPasswordValidation(e.target.value)}
                        />
                        <ButtonsPlain type="submit" className="buttons-plain-7" divClassName="buttons-plain-8"
                                      text="Reset"/>
                    </form>
                </div>
                <div className="flexcontainer-4">
                    <p className="text-2">
                <span className="text-wrapper-6">
                  Forgot your password?
                  <br/>
                </span>
                    </p>
                    <p className="text-2">
                        <span className="text-wrapper-6">We got you covered!</span>
                    </p>
                </div>
                <p className="enter-your-details-t-3">Enter your new password</p>
            </div>
        </div>
    </div>);
};
