import React, {useState} from "react";
import {ButtonsPlain} from "../../components/ButtonsPlain";
import "./style.css";
import {FormsInputActive} from "../../components/FormsInputActive";
import config from "../../config";

export const Recover = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setSuccess(null);

        try {
            const response = await fetch(`${config.SERVER_URL}/auth/generaterecovery`, {
                method: 'POST', headers: {
                    'Content-Type': 'application/json'
                }, body: JSON.stringify({email: email}),
            });

            if (response.ok) {
                setSuccess("An email has been sent to your email address with instructions on how to recover your password.")
                setEmail("");
            } else {
                const errorData = await response.json();

                if (errorData.error) {
                    setError(errorData.error);
                } else {
                    setError("Failed. Please try again.");
                }
            }
        } catch (error) {
            setError("An error occurred. Please try again." + error.message);
        }
    }


    return (
        <div className="recover">
            <div className="overlap-wrapper-4">
                <div className="overlap-6">
                    <div className="gradient-bg-5"/>
                    <div className="image-4">
                        <div className="overlap-group-6">
                            <img className="path-176" alt="Path" src="/img/path.svg"/>
                            <img className="path-copy-4" alt="Path copy" src="/img/path-copy-4.svg"/>
                            <img className="path-177" alt="Path" src="/img/path-191.svg"/>
                            <img className="path-178" alt="Path" src="/img/path-192.svg"/>
                            <img className="path-179" alt="Path" src="/img/path-193.svg"/>
                            <img className="path-180" alt="Path" src="/img/path-194.svg"/>
                            <img className="path-181" alt="Path" src="/img/path-195.svg"/>
                            <img className="path-182" alt="Path" src="/img/path-196.svg"/>
                            <img className="path-183" alt="Path" src="/img/path-197.svg"/>
                            <img className="path-184" alt="Path" src="/img/path-198.svg"/>
                            <img className="path-178" alt="Path" src="/img/path-199.svg"/>
                            <img className="path-179" alt="Path" src="/img/path-200.svg"/>
                            <img className="path-180" alt="Path" src="/img/path-201.svg"/>
                            <img className="path-181" alt="Path" src="/img/path-202.svg"/>
                            <img className="path-182" alt="Path" src="/img/path-203.svg"/>
                            <img className="path-183" alt="Path" src="/img/path-204.svg"/>
                            <img className="path-184" alt="Path" src="/img/path-205.svg"/>
                            <img className="path-185" alt="Path" src="/img/path-206.svg"/>
                            <img className="path-186" alt="Path" src="/img/path-207.svg"/>
                            <img className="path-187" alt="Path" src="/img/path-208.svg"/>
                            <img className="path-188" alt="Path" src="/img/path-209.svg"/>
                            <img className="path-189" alt="Path" src="/img/path-210.svg"/>
                            <img className="path-190" alt="Path" src="/img/path-211.svg"/>
                            <img className="path-191" alt="Path" src="/img/path-212.svg"/>
                            <img className="path-192" alt="Path" src="/img/path-213.svg"/>
                            <img className="shape-31" alt="Shape" src="/img/shape-30.svg"/>
                            <img className="path-193" alt="Path" src="/img/path-214.svg"/>
                            <img className="path-193" alt="Path" src="/img/path-215.svg"/>
                            <img className="path-194" alt="Path" src="/img/path-216.svg"/>
                            <img className="path-194" alt="Path" src="/img/path-217.svg"/>
                            <img className="path-195" alt="Path" src="/img/path-218.svg"/>
                            <img className="path-196" alt="Path" src="/img/path-219.svg"/>
                            <img className="path-197" alt="Path" src="/img/path-220.svg"/>
                            <img className="path-198" alt="Path" src="/img/path-221.svg"/>
                            <img className="path-199" alt="Path" src="/img/path-222.svg"/>
                            <img className="path-200" alt="Path" src="/img/path-223.svg"/>
                            <img className="path-201" alt="Path" src="/img/path-224.svg"/>
                            <img className="path-202" alt="Path" src="/img/path-225.svg"/>
                            <img className="path-203" alt="Path" src="/img/path-226.svg"/>
                            <img className="path-204" alt="Path" src="/img/path-227.svg"/>
                            <img className="path-205" alt="Path" src="/img/path-228.svg"/>
                            <img className="path-206" alt="Path" src="/img/path-229.svg"/>
                            <img className="path-206" alt="Path" src="/img/path-230.svg"/>
                            <img className="path-207" alt="Path" src="/img/path-231.svg"/>
                            <img className="path-208" alt="Path" src="/img/path-232.svg"/>
                            <img className="path-209" alt="Path" src="/img/path-233.svg"/>
                            <img className="path-210" alt="Path" src="/img/path-234.svg"/>
                            <img className="path-211" alt="Path" src="/img/path-235.svg"/>
                            <img className="path-212" alt="Path" src="/img/path-236.svg"/>
                            <img className="path-213" alt="Path" src="/img/path-237.svg"/>
                            <img className="path-214" alt="Path" src="/img/path-238.svg"/>
                            <img className="path-215" alt="Path" src="/img/path-239.svg"/>
                            <img className="path-216" alt="Path" src="/img/path-240.svg"/>
                            <img className="path-193" alt="Path" src="/img/path-241.svg"/>
                            <img className="path-217" alt="Path" src="/img/path-242.svg"/>
                            <img className="path-218" alt="Path" src="/img/path-243.svg"/>
                            <img className="path-219" alt="Path" src="/img/path-244.svg"/>
                            <img className="path-220" alt="Path" src="/img/path-245.svg"/>
                            <img className="path-221" alt="Path" src="/img/path-246.svg"/>
                            <img className="path-222" alt="Path" src="/img/path-247.svg"/>
                            <img className="path-223" alt="Path" src="/img/path-248.svg"/>
                            <img className="path-224" alt="Path" src="/img/path-249.svg"/>
                            <img className="path-225" alt="Path" src="/img/path-250.svg"/>
                            <img className="path-226" alt="Path" src="/img/path-251.svg"/>
                            <img className="path-227" alt="Path" src="/img/path-252.svg"/>
                            <img className="path-228" alt="Path" src="/img/path-253.svg"/>
                            <img className="path-229" alt="Path" src="/img/path-254.svg"/>
                            <img className="path-230" alt="Path" src="/img/path-255.svg"/>
                            <img className="path-231" alt="Path" src="/img/path-256.svg"/>
                            <img className="path-232" alt="Path" src="/img/path-257.svg"/>
                            <img className="path-233" alt="Path" src="/img/path-258.svg"/>
                            <img className="path-234" alt="Path" src="/img/path-259.svg"/>
                            <img className="path-235" alt="Path" src="/img/path-260.svg"/>
                            <img className="path-236" alt="Path" src="/img/path-261.svg"/>
                            <img className="path-237" alt="Path" src="/img/path-262.svg"/>
                            <img className="path-238" alt="Path" src="/img/path-263.svg"/>
                            <img className="path-239" alt="Path" src="/img/path-264.svg"/>
                            <img className="path-240" alt="Path" src="/img/path-265.svg"/>
                            <img className="path-241" alt="Path" src="/img/path-266.svg"/>
                            <img className="path-242" alt="Path" src="/img/path-267.svg"/>
                            <img className="path-243" alt="Path" src="/img/path-268.svg"/>
                            <img className="path-244" alt="Path" src="/img/path-269.svg"/>
                            <img className="path-245" alt="Path" src="/img/path-270.svg"/>
                            <img className="path-246" alt="Path" src="/img/path-271.svg"/>
                            <img className="group-11" alt="Group" src="/img/group-8.png"/>
                            <img className="oval-31" alt="Oval" src="/img/oval-30.svg"/>
                            <img className="oval-32" alt="Oval" src="/img/oval-31.svg"/>
                            <img className="path-247" alt="Path" src="/img/path-272.svg"/>
                            <img className="path-248" alt="Path" src="/img/path-273.svg"/>
                            <img className="path-249" alt="Path" src="/img/path-274.svg"/>
                            <img className="path-250" alt="Path" src="/img/path-275.svg"/>
                            <img className="path-251" alt="Path" src="/img/path-276.svg"/>
                            <img className="path-252" alt="Path" src="/img/path-277.svg"/>
                            <img className="path-253" alt="Path" src="/img/path-278.svg"/>
                            <img className="path-254" alt="Path" src="/img/path-279.svg"/>
                        </div>
                    </div>
                    <div className="bg-6"/>
                    <div className="form-5">
                        <form onSubmit={handleSubmit}>
                            {error && <div className="error-message">{error}</div>}
                            {success && <div className="success-message">{success}</div>}
                            <FormsInputActive
                                bgClassName="forms-input-inactive-15"
                                className="forms-input-inactive-14"
                                iconClassName="forms-input-inactive-16"
                                text={email}
                                title="Email"
                                type="email"
                                required={true}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <ButtonsPlain
                                type="submit"
                                className="buttons-plain-11"
                                divClassName="buttons-plain-12"
                                text="Recover"/>
                        </form>
                    </div>
                    <div className="flexcontainer-5">
                        <p className="text-3">
              <span className="text-wrapper-9">
                Lost your password?
                <br/>
              </span>
                        </p>
                        <p className="text-3">
                            <span className="text-wrapper-9">Enter your details to recover.</span>
                        </p>
                    </div>
                    <p className="enter-your-details-t-4">Enter your details to proceed further</p>
                </div>
            </div>
        </div>
    );
};
