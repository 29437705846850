import React, {useState, useEffect} from "react";
import {ButtonsPlain} from "../ButtonsPlain";
import {useNavigate} from "react-router-dom";
import {ButtonsPlainWrapper} from "../ButtonsPlainWrapper";
import "./style.css";
import authService from '../../authService';

export const NavBar = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(authService.isLoggedIn());

    useEffect(() => {
        const checkAuthStatus = () => {
            setIsLoggedIn(authService.isLoggedIn());
        };

        const intervalId = setInterval(checkAuthStatus, 5000);

        return () => clearInterval(intervalId);
    }, []);

    const handleLogout = () => {
        authService.logout();
        setIsLoggedIn(false);
        navigate('/sign-in');
    };

    const handleSettings = () => {
        navigate('/settings');
    };

    const handleLogin = () => {
        navigate('/sign-in');
    }

    const handleSignup = () => {
        navigate('/sign-up');
    }

    return (
        <div className="navbar">
            <div className="overlap-group">
                <div className="overlap-group-wrapper">
                    <div className="page-title-wrapper">
                        <div className="page-title">SellerGlory</div>
                    </div>
                </div>
                {!isLoggedIn ? (
                    <>
                        <ButtonsPlain
                            className="buttons-plain-primary-resting"
                            divClassName="buttons-plain-primary-resting-instance"
                            text="Log In"
                            onClick={handleLogin}
                        />
                        <ButtonsPlain
                            className="buttons-plain-primary-active"
                            divClassName="buttons-plain-instance"
                            text="Sign Up"
                            onClick={handleSignup}
                        />
                    </>
                ) : (
                    <>
                        <ButtonsPlain
                            className="buttons-plain-primary-resting"
                            divClassName="buttons-plain-primary-resting-instance"
                            text="Logout"
                            onClick={handleLogout}
                        />
                        <ButtonsPlain
                            className="buttons-plain-primary-active"
                            divClassName="buttons-plain-instance"
                            text="Settings"
                            onClick={handleSettings}
                        />
                    </>
                )}
            </div>
        </div>
    );
};
