import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const FormsInputActive = ({className, bgClassName, text, type = 'text', title, onChange, required = true}) => {
    return (
        <div className={`forms-input-active ${className}`}>
            <div className={`bg ${bgClassName}`}/>
            <input
                className="placeholder"
                value={text}
                placeholder="Start typing..."
                onChange={onChange}
                type={type}
                required={required}
            />
            <div className="field-title">{title}</div>
        </div>
    );
};

FormsInputActive.propTypes = {
    text: PropTypes.string,
    title: PropTypes.string,
};
