import React, {useState} from "react";
import {ButtonsPlain} from "../../components/ButtonsPlain";
import {ButtonsPlainWrapper} from "../../components/ButtonsPlainWrapper";
import {FormsInputActive} from "../../components/FormsInputActive";
import {useNavigate} from "react-router-dom";
import "./style.css";
import authService from '../../authService';
import config from "../../config";

export const SignUp = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const navigate = useNavigate();


    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            // Replace with your actual API call
            const response = await fetch(`${config.SERVER_URL}/auth/signup`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    email: email,
                    password: password,
                    name: name,
                }),
            });

            if (response.ok) {
                navigate('/sign-in');
            } else {
                // Handle registration error
            }
        } catch (error) {
            // Handle network or other errors
        }
    };

    const handleLogin = () => {
        navigate('/sign-in');
    }


    return (
        <div className="sign-up">
            <div className="overlap-wrapper">
                <div className="overlap">
                    <div className="gradient-bg"/>
                    <div className="image">
                        <div className="overlap-group-2">
                            <img className="path" alt="Path" src="/img/path-49.svg"/>
                            <img className="path-copy" alt="Path copy" src="/img/path-copy-1.svg"/>
                            <img className="img" alt="Path" src="/img/path-50.svg"/>
                            <img className="path-2" alt="Path" src="/img/path-51.svg"/>
                            <div className="rectangle"/>
                            <div className="rectangle-2"/>
                            <img className="path-3" alt="Path" src="/img/path-52.svg"/>
                            <img className="path-4" alt="Path" src="/img/path-53.svg"/>
                            <img className="path-5" alt="Path" src="/img/path-54.svg"/>
                            <img className="path-6" alt="Path" src="/img/path-55.svg"/>
                            <img className="path-7" alt="Path" src="/img/path-56.svg"/>
                            <img className="path-8" alt="Path" src="/img/path-57.svg"/>
                            <div className="rectangle-3"/>
                            <img className="path-9" alt="Path" src="/img/path-58.svg"/>
                            <div className="rectangle-4"/>
                            <img className="path-10" alt="Path" src="/img/path-59.svg"/>
                            <img className="path-10" alt="Path" src="/img/path-60.svg"/>
                            <img className="path-11" alt="Path" src="/img/path-59.svg"/>
                            <img className="path-12" alt="Path" src="/img/path-62.svg"/>
                            <img className="path-13" alt="Path" src="/img/path-63.svg"/>
                            <img className="path-14" alt="Path" src="/img/path-64.svg"/>
                            <img className="path-15" alt="Path" src="/img/path-65.svg"/>
                            <img className="path-16" alt="Path" src="/img/path-66.svg"/>
                            <img className="path-17" alt="Path" src="/img/path-67.svg"/>
                            <img className="path-18" alt="Path" src="/img/path-68.svg"/>
                            <img className="path-18" alt="Path" src="/img/path-69.svg"/>
                            <img className="path-19" alt="Path" src="/img/path-68.svg"/>
                            <img className="path-20" alt="Path" src="/img/path-71.svg"/>
                            <img className="path-21" alt="Path" src="/img/path-72.svg"/>
                            <img className="path-22" alt="Path" src="/img/path-73.svg"/>
                            <div className="rectangle-5"/>
                            <div className="rectangle-6"/>
                            <img className="rectangle-7" alt="Rectangle" src="/img/rectangle-1.svg"/>
                            <img className="rectangle-8" alt="Rectangle" src="/img/rectangle-2.svg"/>
                            <img className="rectangle-9" alt="Rectangle" src="/img/rectangle-3.svg"/>
                            <img className="oval" alt="Oval" src="/img/oval-1.svg"/>
                            <img className="oval-2" alt="Oval" src="/img/oval-2.svg"/>
                            <img className="path-23" alt="Path" src="/img/path-74.svg"/>
                            <img className="oval-3" alt="Oval" src="/img/oval-3.svg"/>
                            <img className="oval-4" alt="Oval" src="/img/oval-3.svg"/>
                            <img className="path-24" alt="Path" src="/img/path-75.svg"/>
                            <img className="path-25" alt="Path" src="/img/path-76.svg"/>
                            <img className="path-26" alt="Path" src="/img/path-77.svg"/>
                            <img className="oval-5" alt="Oval" src="/img/oval-5.svg"/>
                            <img className="oval-6" alt="Oval" src="/img/oval-6.svg"/>
                            <img className="path-27" alt="Path" src="/img/path-78.svg"/>
                            <img className="path-28" alt="Path" src="/img/path-79.svg"/>
                            <img className="path-29" alt="Path" src="/img/path-80.svg"/>
                            <img className="group" alt="Group" src="/img/group-2.png"/>
                            <img className="group-2" alt="Group" src="/img/group-3.png"/>
                            <img className="group-3" alt="Group" src="/img/group-4.png"/>
                            <div className="rectangle-10"/>
                            <div className="rectangle-11"/>
                            <img className="rectangle-12" alt="Rectangle" src="/img/rectangle-4.svg"/>
                            <img className="rectangle-13" alt="Rectangle" src="/img/rectangle-5.svg"/>
                            <img className="rectangle-14" alt="Rectangle" src="/img/rectangle-6.svg"/>
                            <img className="rectangle-15" alt="Rectangle" src="/img/rectangle-7.svg"/>
                            <img className="rectangle-16" alt="Rectangle" src="/img/rectangle-8.svg"/>
                            <img className="rectangle-17" alt="Rectangle" src="/img/rectangle-9.svg"/>
                            <img className="path-30" alt="Path" src="/img/path-81.svg"/>
                            <img className="path-31" alt="Path" src="/img/path-82.svg"/>
                            <img className="path-32" alt="Path" src="/img/path-83.svg"/>
                            <img className="path-33" alt="Path" src="/img/path-84.svg"/>
                            <img className="path-33" alt="Path" src="/img/path-85.svg"/>
                            <img className="path-33" alt="Path" src="/img/path-86.svg"/>
                            <img className="path-33" alt="Path" src="/img/path-87.svg"/>
                            <img className="path-34" alt="Path" src="/img/path-88.svg"/>
                            <img className="path-34" alt="Path" src="/img/path-89.svg"/>
                            <img className="path-35" alt="Path" src="/img/path-90.svg"/>
                            <img className="path-36" alt="Path" src="/img/path-91.svg"/>
                            <img className="path-36" alt="Path" src="/img/path-92.svg"/>
                            <img className="path-36" alt="Path" src="/img/path-93.svg"/>
                            <img className="group-4" alt="Group" src="/img/group-5.png"/>
                            <img className="path-37" alt="Path" src="/img/path-94.svg"/>
                            <img className="path-38" alt="Path" src="/img/path-95.svg"/>
                            <img className="path-39" alt="Path" src="/img/path-96.svg"/>
                            <img className="path-40" alt="Path" src="/img/path-97.svg"/>
                            <img className="path-41" alt="Path" src="/img/path-98.svg"/>
                            <img className="path-42" alt="Path" src="/img/path-99.svg"/>
                            <img className="path-43" alt="Path" src="/img/path-100.svg"/>
                            <img className="path-44" alt="Path" src="/img/path-101.svg"/>
                            <img className="path-45" alt="Path" src="/img/path-102.svg"/>
                            <img className="path-46" alt="Path" src="/img/path-103.svg"/>
                            <img className="path-47" alt="Path" src="/img/path-104.svg"/>
                            <img className="path-48" alt="Path" src="/img/path-103.svg"/>
                            <img className="path-49" alt="Path" src="/img/path-106.svg"/>
                            <img className="path-50" alt="Path" src="/img/path-107.svg"/>
                            <img className="path-51" alt="Path" src="/img/path-108.svg"/>
                            <img className="path-52" alt="Path" src="/img/path-109.svg"/>
                            <img className="path-53" alt="Path" src="/img/path-94.svg"/>
                            <img className="path-54" alt="Path" src="/img/path-108.svg"/>
                            <img className="path-55" alt="Path" src="/img/path-112.svg"/>
                            <img className="path-56" alt="Path" src="/img/path-113.svg"/>
                            <img className="path-57" alt="Path" src="/img/path-114.svg"/>
                            <img className="path-58" alt="Path" src="/img/path-115.svg"/>
                            <img className="path-59" alt="Path" src="/img/path-116.svg"/>
                            <img className="path-60" alt="Path" src="/img/path-117.svg"/>
                            <img className="path-61" alt="Path" src="/img/path-118.svg"/>
                            <img className="path-62" alt="Path" src="/img/path-119.svg"/>
                            <img className="path-63" alt="Path" src="/img/path-120.svg"/>
                            <img className="path-64" alt="Path" src="/img/path-121.svg"/>
                            <img className="path-65" alt="Path" src="/img/path-122.svg"/>
                            <img className="path-66" alt="Path" src="/img/path-107.svg"/>
                            <img className="path-67" alt="Path" src="/img/path-124.svg"/>
                            <img className="path-68" alt="Path" src="/img/path-125.svg"/>
                            <img className="path-69" alt="Path" src="/img/path-126.svg"/>
                            <img className="path-70" alt="Path" src="/img/path-100.svg"/>
                            <img className="path-71" alt="Path" src="/img/path-128.svg"/>
                            <img className="path-72" alt="Path" src="/img/path-129.svg"/>
                            <img className="path-73" alt="Path" src="/img/path-130.svg"/>
                            <img className="path-74" alt="Path" src="/img/path-131.svg"/>
                            <img className="path-75" alt="Path" src="/img/path-132.svg"/>
                            <img className="path-76" alt="Path" src="/img/path-133.svg"/>
                            <img className="path-77" alt="Path" src="/img/path-134.svg"/>
                            <img className="path-78" alt="Path" src="/img/path-135.svg"/>
                            <img className="path-79" alt="Path" src="/img/path-136.svg"/>
                            <img className="path-80" alt="Path" src="/img/path-137.svg"/>
                            <img className="path-80" alt="Path" src="/img/path-138.svg"/>
                            <img className="path-81" alt="Path" src="/img/path-139.svg"/>
                        </div>
                    </div>
                    <div className="bg-2"/>
                    <div className="form">
                        <form onSubmit={handleSubmit}>
                            <FormsInputActive
                                bgClassName="forms-input-active-instance"
                                className="forms-input-active-icon-on-right"
                                iconClassName="forms-input-active-icon-on-right-instance"
                                text={name}
                                title="Full name"
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                            />
                            <FormsInputActive
                                bgClassName="forms-input-active-instance"
                                className="design-component-instance-node"
                                iconClassName="forms-input-active-icon-on-right-instance"
                                text={email}
                                title="Email"
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <FormsInputActive
                                bgClassName="forms-input-inactive-2"
                                className="forms-input-inactive-instance"
                                iconClassName="forms-input-inactive-3"
                                text={password}
                                title="Password"
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <ButtonsPlain
                                type="submit"
                                className="buttons-plain-primary-active"
                                divClassName="buttons-plain-instance"
                                text="Sign Up"
                            />
                            <ButtonsPlainWrapper
                                className="buttons-plain-primary-resting"
                                divClassName="buttons-plain-primary-resting-instance"
                                text="Sign In"
                                onClick={handleLogin}
                            />
                        </form>
                    </div>
                    <div className="flexcontainer">
                        <p className="text">
              <span className="span">
                Welcome to SellerGlory
                <br/>
              </span>
                        </p>
                        <p className="text">
                            <span className="span">Sign Up to getting started.</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
