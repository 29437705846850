import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const ButtonsPlain = ({className, divClassName, text, type = "button", onClick}) => {
    return (
        <button type={type} className={`buttons-plain ${className}`} onClick={onClick}>
            <div className={`lable ${divClassName}`}>{text}</div>
        </button>
    );
};

ButtonsPlain.propTypes = {
    text: PropTypes.string,
};
