import React from "react";
import {Route, Routes} from "react-router-dom";
import {SignUp} from "./screens/SignUp";
import {SignIn} from "./screens/SignIn";
import {AccountSettings} from "./screens/AccountSettings";
import {NavBar} from "./components/NavBar";
import {Recover} from "./screens/Recover";
import {ResetPassword} from "./screens/ResetPassword/ResetPassword";

export const App = () => {
    return (
        <>
            <NavBar/>
            <Routes>
                <Route path="/*" element={<SignIn/>}/>
                <Route path="/sign-in" element={<SignIn/>}/>
                <Route path="/sign-up" element={<SignUp/>}/>
                <Route path="/settings" element={<AccountSettings/>}/>
                <Route path="/recover" element={<Recover/>}/>
                <Route path="/password-reset" element={<ResetPassword/>}/>
            </Routes>
        </>
    );
};
