/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const CountersIconWeb = ({
  className,
  overlapClassName,
  icoClassName,
  overlapGroupClassName,
  icoCircleClassName,
  text = "",
  text1 = "1.345",
  text2 = "New sales",
}) => {
  return (
    <div className={`counters-icon-web ${className}`}>
      <div className={`overlap ${overlapClassName}`}>
        <div className={`overlap-group-wrapper ${icoClassName}`}>
          <div className={`overlap-group ${overlapGroupClassName}`}>
            <div className={`ico-circle ${icoCircleClassName}`}>{text}</div>
          </div>
        </div>
        <div className="numbers">
          <div className="number">{text1}</div>
        </div>
        <div className="title">{text2}</div>
      </div>
    </div>
  );
};

CountersIconWeb.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
};
